import React from 'react';
import PropTypes from 'prop-types';

const PhotoIc = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.9996 10C28.983 10 28.0496 9.41666 27.583 8.51666L26.383 6.1C25.6163 4.58333 23.6163 3.33333 21.9163 3.33333H18.0996C16.383 3.33333 14.383 4.58333 13.6163 6.1L12.4163 8.51666C11.9496 9.41666 11.0163 10 9.99963 10C6.38296 10 3.51629 13.05 3.74963 16.65L4.61629 30.4167C4.81629 33.85 6.66629 36.6667 11.2663 36.6667H28.733C33.333 36.6667 35.1663 33.85 35.383 30.4167L36.2496 16.65C36.483 13.05 33.6163 10 29.9996 10ZM17.4996 12.0833H22.4996C23.183 12.0833 23.7496 12.65 23.7496 13.3333C23.7496 14.0167 23.183 14.5833 22.4996 14.5833H17.4996C16.8163 14.5833 16.2496 14.0167 16.2496 13.3333C16.2496 12.65 16.8163 12.0833 17.4996 12.0833ZM19.9996 30.2C16.8996 30.2 14.3663 27.6833 14.3663 24.5667C14.3663 21.45 16.883 18.9333 19.9996 18.9333C23.1163 18.9333 25.633 21.45 25.633 24.5667C25.633 27.6833 23.0996 30.2 19.9996 30.2Z" fill={color}/>
    </svg>
  );
};

PhotoIc.propTypes = {
  color: PropTypes.string,
}
PhotoIc.defaultProps = {
  color: "#333333",
}

export default PhotoIc;

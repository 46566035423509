import React from 'react';
import PropTypes from 'prop-types';

const BatchesIc = ({ color }) => {
	return (
		<svg
			width='25'
			height='25'
			viewBox='0 0 24 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M17.8099 6.48731V7.22731L14.2699 5.17731C12.9299 4.40731 11.0599 4.40731 9.72994 5.17731L6.18994 7.23731V6.48731C6.18994 4.23731 7.41994 2.99731 9.66994 2.99731H14.3299C16.5799 2.99731 17.8099 4.23731 17.8099 6.48731Z'
				fill={color}
			/>
			<path
				d='M17.84 8.9673L17.7 8.89731L16.34 8.1173L13.52 6.4873C12.66 5.9873 11.34 5.9873 10.48 6.4873L7.66 8.10731L6.3 8.9073L6.12 8.9973C4.37 10.1773 4.25 10.3973 4.25 12.2873V16.8073C4.25 18.6973 4.37 18.9173 6.16 20.1273L10.48 22.6173C10.91 22.8773 11.45 22.9873 12 22.9873C12.54 22.9873 13.09 22.8673 13.52 22.6173L17.88 20.0973C19.64 18.9173 19.75 18.7073 19.75 16.8073V12.2873C19.75 10.3973 19.63 10.1773 17.84 8.9673ZM14.79 14.4973L14.18 15.2473C14.08 15.3573 14.01 15.5673 14.02 15.7173L14.08 16.6773C14.12 17.2673 13.7 17.5673 13.15 17.3573L12.26 16.9973C12.12 16.9473 11.89 16.9473 11.75 16.9973L10.86 17.3473C10.31 17.5673 9.89 17.2573 9.93 16.6673L9.99 15.7073C10 15.5573 9.93 15.3473 9.83 15.2373L9.21 14.4973C8.83 14.0473 9 13.5473 9.57 13.3973L10.5 13.1573C10.65 13.1173 10.82 12.9773 10.9 12.8573L11.42 12.0573C11.74 11.5573 12.25 11.5573 12.58 12.0573L13.1 12.8573C13.18 12.9873 13.36 13.1173 13.5 13.1573L14.43 13.3973C15 13.5473 15.17 14.0473 14.79 14.4973Z'
				fill={color}
			/>
		</svg>
	);
};

BatchesIc.propTypes = {
	color: PropTypes.string,
};
BatchesIc.defaultProps = {
	color: '#333333',
};

export default BatchesIc;

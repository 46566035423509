import React from 'react'
import PropTypes from 'prop-types';

const CupIcon = ({fill}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.25 18.25H9C7.9 18.25 7 19.15 7 20.25V20.5H6C5.59 20.5 5.25 20.84 5.25 21.25C5.25 21.66 5.59 22 6 22H18C18.41 22 18.75 21.66 18.75 21.25C18.75 20.84 18.41 20.5 18 20.5H17V20.25C17 19.15 16.1 18.25 15 18.25H12.75V15.96C12.5 15.99 12.25 16 12 16C11.75 16 11.5 15.99 11.25 15.96V18.25Z" fill={fill}/>
      <path d="M18.4803 11.64C19.1403 11.39 19.7203 10.98 20.1803 10.52C21.1103 9.49 21.7203 8.26 21.7203 6.82C21.7203 5.38 20.5903 4.25 19.1503 4.25H18.5903C17.9403 2.92 16.5803 2 15.0003 2H9.00027C7.42027 2 6.06027 2.92 5.41027 4.25H4.85027C3.41027 4.25 2.28027 5.38 2.28027 6.82C2.28027 8.26 2.89027 9.49 3.82027 10.52C4.28027 10.98 4.86027 11.39 5.52027 11.64C6.56027 14.2 9.06027 16 12.0003 16C14.9403 16 17.4403 14.2 18.4803 11.64ZM14.8403 8.45L14.2203 9.21C14.1203 9.32 14.0503 9.54 14.0603 9.69L14.1203 10.67C14.1603 11.27 13.7303 11.58 13.1703 11.36L12.2603 11C12.1203 10.95 11.8803 10.95 11.7403 11L10.8303 11.36C10.2703 11.58 9.84027 11.27 9.88027 10.67L9.94027 9.69C9.95027 9.54 9.88027 9.32 9.78027 9.21L9.16027 8.45C8.77027 7.99 8.94028 7.48 9.52028 7.33L10.4703 7.09C10.6203 7.05 10.8003 6.91 10.8803 6.78L11.4103 5.96C11.7403 5.45 12.2603 5.45 12.5903 5.96L13.1203 6.78C13.2003 6.91 13.3803 7.05 13.5303 7.09L14.4803 7.33C15.0603 7.48 15.2303 7.99 14.8403 8.45Z" fill={fill}/>
    </svg>
  )
}

CupIcon.propTypes = {
  fill: PropTypes.string
}
CupIcon.defaultProps = {
  fill: "#FBFBFB"
}

export default CupIcon

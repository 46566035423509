import React from "react";
import PropTypes from "prop-types";

const TeacherIc = ({ color, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_560_165)">
        <path
          d="M29.8987 2.13563C30.4652 2.13563 31.0084 2.36063 31.4089 2.76114C31.8094 3.16164 32.0344 3.70485 32.0344 4.27125V21.3563C32.0344 21.9227 31.8094 22.4659 31.4089 22.8664C31.0084 23.2669 30.4652 23.4919 29.8987 23.4919H9.42665C8.29393 23.4921 7.2077 23.9423 6.40687 24.7434L2.13562 29.0146V4.27125C2.13562 3.70485 2.36063 3.16164 2.76113 2.76114C3.16164 2.36063 3.70485 2.13563 4.27125 2.13563H29.8987ZM4.27125 0C3.13844 0 2.05203 0.450006 1.25102 1.25102C0.450005 2.05204 0 3.13844 0 4.27125L0 31.5923C4.48799e-05 31.8036 0.0627715 32.0101 0.180241 32.1857C0.29771 32.3614 0.464641 32.4982 0.659907 32.5789C0.855172 32.6596 1.06999 32.6806 1.27718 32.6392C1.48437 32.5977 1.67461 32.4958 1.82382 32.3462L7.91676 26.2532C8.31718 25.8527 8.86029 25.6276 9.42665 25.6275H29.8987C31.0316 25.6275 32.118 25.1775 32.919 24.3765C33.72 23.5755 34.17 22.4891 34.17 21.3563V4.27125C34.17 3.13844 33.72 2.05204 32.919 1.25102C32.118 0.450006 31.0316 0 29.8987 0L4.27125 0Z"
          fill={color}
        />
        <path
          d="M6.40625 7.47455C6.40625 7.19135 6.51875 6.91975 6.71901 6.71949C6.91926 6.51924 7.19086 6.40674 7.47406 6.40674H26.6947C26.9779 6.40674 27.2495 6.51924 27.4497 6.71949C27.65 6.91975 27.7625 7.19135 27.7625 7.47455C27.7625 7.75775 27.65 8.02935 27.4497 8.22961C27.2495 8.42986 26.9779 8.54236 26.6947 8.54236H7.47406C7.19086 8.54236 6.91926 8.42986 6.71901 8.22961C6.51875 8.02935 6.40625 7.75775 6.40625 7.47455ZM6.40625 12.8136C6.40625 12.5304 6.51875 12.2588 6.71901 12.0586C6.91926 11.8583 7.19086 11.7458 7.47406 11.7458H26.6947C26.9779 11.7458 27.2495 11.8583 27.4497 12.0586C27.65 12.2588 27.7625 12.5304 27.7625 12.8136C27.7625 13.0968 27.65 13.3684 27.4497 13.5687C27.2495 13.7689 26.9779 13.8814 26.6947 13.8814H7.47406C7.19086 13.8814 6.91926 13.7689 6.71901 13.5687C6.51875 13.3684 6.40625 13.0968 6.40625 12.8136ZM6.40625 18.1527C6.40625 17.8695 6.51875 17.5979 6.71901 17.3976C6.91926 17.1974 7.19086 17.0849 7.47406 17.0849H18.1522C18.4354 17.0849 18.707 17.1974 18.9072 17.3976C19.1075 17.5979 19.22 17.8695 19.22 18.1527C19.22 18.4359 19.1075 18.7075 18.9072 18.9077C18.707 19.108 18.4354 19.2205 18.1522 19.2205H7.47406C7.19086 19.2205 6.91926 19.108 6.71901 18.9077C6.51875 18.7075 6.40625 18.4359 6.40625 18.1527Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_560_165">
          <rect width="34.17" height="34.17" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

TeacherIc.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
TeacherIc.defaultProps = {
  color: "#333333",
  height: "35px",
  width: "35px",
};

export default TeacherIc;

import React, { useState } from "react";
import styles from "./Otp.module.scss";
import NavBar from "../../components/NavBar/NavBar";
import IconMain from "../../components/Signup-Login/IconMain";
import AuthButton from "../../components/Signup-Login/AuthButton";

const Otp = () => {
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const [focus, setFocus] = useState("");
  const onOtpChange = (e) => {
    const target = e.target;

    if (!parseInt(target.value)) {
      setOtp("");
      return;
    } else {
      switch (target.name) {
        case "one":
          setFocus("two");
          setOtp((prevOtp) => ({
            ...prevOtp,
            otp1: target.value,
          }));
          document.querySelector("#two").focus();
          break;
        case "two":
          setFocus("three");
          setOtp((prevOtp) => ({
            ...prevOtp,
            otp2: target.value,
          }));
          document.querySelector("#three").focus();
          break;
        case "three":
          setFocus("four");
          setOtp((prevOtp) => ({
            ...prevOtp,
            otp3: target.value,
          }));
          document.querySelector("#four").focus();
          break;
        case "four":
          setFocus("five");
          setOtp((prevOtp) => ({
            ...prevOtp,
            otp4: target.value,
          }));
          document.querySelector("#five").focus();
          break;
        case "five":
          setFocus("six");
          setOtp((prevOtp) => ({
            ...prevOtp,
            otp5: target.value,
          }));
          document.querySelector("#six").focus();
          break;
        case "six":
          setOtp((prevOtp) => ({
            ...prevOtp,
            otp6: target.value,
          }));
          break;
        default:
          break;
      }
    }
  };
  const submitForm = (e) => {
    e.preventDefault();
    console.log(otp);
  };
  return (
    <>
      <div className={styles.container}>
        <NavBar page="signup" />
        <div className={styles.wrapper}>
          <IconMain />
          <form className={styles.submitAccountDetails} onSubmit={submitForm}>
            <div className={styles.otpInput}>
              <input
                className={`${styles.otpNumOne} ${
                  focus === "one" || (focus === "" && styles.focus)
                }`}
                focus={focus}
                value={otp.otp1}
                maxLength="1"
                type="text"
                name="one"
                id="one"
                onChange={onOtpChange}
                autoFocus
              ></input>
              <input
                className={`${styles.otpNumTwo} ${
                  focus === "two" && styles.focus
                }`}
                focus={focus}
                value={otp.otp2}
                maxLength="1"
                id="two"
                name="two"
                type="text"
                onChange={onOtpChange}
              ></input>
              <input
                className={`${styles.otpNumThree} ${
                  focus === "three" && styles.focus
                }`}
                focus={focus}
                value={otp.otp3}
                id="three"
                onChange={onOtpChange}
                name="three"
                maxLength="1"
                type="text"
              ></input>
              <input
                className={`${styles.otpNumFour} ${
                  focus === "four" && styles.focus
                }`}
                focus={focus}
                value={otp.otp4}
                id="four"
                onChange={onOtpChange}
                name="four"
                maxLength="1"
                type="text"
              ></input>
              <input
                className={`${styles.otpNumFive} ${
                  focus === "five" && styles.focus
                }`}
                focus={focus}
                value={otp.otp5}
                id="five"
                onChange={onOtpChange}
                name="five"
                maxLength="1"
                type="text"
              ></input>
              <input
                className={`${styles.otpNumSix} ${
                  focus === "six" && styles.focus
                }`}
                focus={focus}
                value={otp.otp6}
                id="six"
                onChange={onOtpChange}
                name="six"
                maxLength="1"
                type="text"
              ></input>
            </div>
            <p className={styles.resendOtp}>
              Didn't recieve the OTP?{" "}
              <button className={styles.resendOtpLink}>Resend</button>
            </p>
            <AuthButton>Continue</AuthButton>
          </form>
        </div>
      </div>
    </>
  );
};

export default Otp;

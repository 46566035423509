import React from 'react'
import PropTypes from 'prop-types';

const EnterIcon = ({fill}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.1761 10.0267V17.8667C27.1761 18.32 26.7491 18.64 26.3349 18.5067L21.2491 16.88C19.8514 16.44 18.3502 16.8133 17.3279 17.8667C16.2926 18.9333 15.9173 20.4933 16.3573 21.9333L17.9232 27.1333C18.0526 27.56 17.742 28 17.302 28H9.73142C5.26671 28 2.58789 25.2533 2.58789 20.64V10.0267C2.58789 5.41332 5.26671 2.66666 9.73142 2.66666H20.0326C24.4973 2.66666 27.1761 5.41332 27.1761 10.0267Z" fill={fill}/>
      <path d="M28.419 25.12L26.3096 25.8533C25.7272 26.0533 25.2613 26.52 25.0672 27.1333L24.3555 29.3067C23.7472 31.1867 21.1849 31.1467 20.6155 29.2667L18.2213 21.3333C17.7555 19.76 19.166 18.2933 20.6802 18.7867L28.3931 21.2533C30.2049 21.84 30.2308 24.4933 28.419 25.12Z" fill={fill}/>
    </svg>
  );
}

EnterIcon.propTypes = {
  fill: PropTypes.string
}
EnterIcon.defaultProps = {
  fill: "#333333"
}

export default EnterIcon;

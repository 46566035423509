import React from "react";
import PropTypes from "prop-types";

const InstituteIc = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0002 8.7479C15.4423 8.7479 15.8663 8.57227 16.1789 8.25965C16.4916 7.94703 16.6672 7.52302 16.6672 7.0809C16.6672 6.63879 16.4916 6.21478 16.1789 5.90215C15.8663 5.58953 15.4423 5.4139 15.0002 5.4139C14.5581 5.4139 14.1341 5.58953 13.8214 5.90215C13.5088 6.21478 13.3332 6.63879 13.3332 7.0809C13.3332 7.52302 13.5088 7.94703 13.8214 8.25965C14.1341 8.57227 14.5581 8.7479 15.0002 8.7479ZM27.0002 23.6799V12.9999H27.1842C28.9702 12.9999 29.7042 10.7039 28.2442 9.6699L16.5442 1.3659C16.0931 1.04545 15.5535 0.873291 15.0002 0.873291C14.4469 0.873291 13.9073 1.04545 13.4562 1.3659L1.75219 9.6699C0.296191 10.7039 1.02619 12.9979 2.81219 12.9979H3.00019V23.6779C1.79819 24.4499 1.00019 25.7979 1.00019 27.3319V29.9979C1.00019 30.2631 1.10555 30.5175 1.29308 30.705C1.48062 30.8925 1.73497 30.9979 2.00019 30.9979H28.0002C28.2654 30.9979 28.5198 30.8925 28.7073 30.705C28.8948 30.5175 29.0002 30.2631 29.0002 29.9979V27.3319C29.0002 25.7979 28.2042 24.4499 27.0002 23.6799ZM14.6122 2.9979C14.7247 2.91817 14.8593 2.87535 14.9972 2.87535C15.1351 2.87535 15.2696 2.91817 15.3822 2.9979L26.6602 10.9979H3.33619L14.6142 2.9979H14.6122ZM24.9962 12.9979V23.0099C24.8857 23.0017 24.775 22.9977 24.6642 22.9979H20.9962V12.9999H24.9962V12.9979ZM5.00019 23.0099V12.9999H9.00019V22.9999H5.33219C5.22019 22.9999 5.10819 23.0039 4.99819 23.0119L5.00019 23.0099ZM5.33419 24.9979H24.6682C25.9562 24.9979 27.0002 26.0419 27.0002 27.3319V28.9979H3.00019V27.3319C3.00019 26.0419 4.04619 24.9979 5.33419 24.9979ZM14.0002 22.9979H11.0002V12.9999H14.0002V22.9999V22.9979ZM16.0002 22.9979V12.9999H19.0002V22.9999H16.0002V22.9979Z"
        fill={color}
      />
    </svg>
  );
};

InstituteIc.propTypes = {
  color: PropTypes.string,
};
InstituteIc.defaultProps = {
  color: "#333333",
};

export default InstituteIc;

export const details = [
  {
    id: 1,
    name: "ANJANNA JAH",
    batchId: "OH123_3",
    img: "https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg",
    phoneNumber: "+44 567 89 345",
    email: "Anjanna@gmail.com",
    course: "Typography",
    status: "Pending",
  },
  {
    id: 2,
    name: "ANJANNA JAH",
    batchId: "OH123_3",
    img: "https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg",
    phoneNumber: "+44 567 89 345",
    email: "Anjanna@gmail.com",
    course: "Typography",
    status: "Accepted",
  },
  {
    id: 3,
    name: "ANJANNA JAH",
    batchId: "OH123_3",
    img: "https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg",
    phoneNumber: "+44 567 89 345",
    email: "Anjanna@gmail.com",
    course: "Typography",
    status: "Rejected",
  },
  {
    id: 4,
    name: "ANJANNA JAH",
    batchId: "OH123_3",
    img: "https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg",
    phoneNumber: "+44 567 89 345",
    email: "Anjanna@gmail.com",
    course: "Typography",
    status: "Pending",
  },
];

import React from 'react';
import PropTypes from 'prop-types';

const OutIc = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9333 3.33328C17.1333 3.33328 16.5 3.96662 16.5 4.76662V18.8666H22.6167L20 16.2499C19.5167 15.7666 19.5167 14.9666 20 14.4833C20.4833 13.9999 21.2833 13.9999 21.7667 14.4833L26.5 19.2333C26.9833 19.7166 26.9833 20.5166 26.5 21L21.7667 25.75C21.5167 26 21.2 26.1166 20.8833 26.1166C20.5667 26.1166 20.25 26 20 25.75C19.5167 25.2666 19.5167 24.4666 20 23.9833L22.6 21.3833H16.5V35.2666C16.5 36.0499 17.1333 36.7 17.9333 36.7C27.75 36.7 34.6 29.85 34.6 20.0333C34.6 10.2166 27.7333 3.33328 17.9333 3.33328Z" fill={color}/>
      <path d="M6.65039 18.8667C5.96706 18.8667 5.40039 19.4333 5.40039 20.1167C5.40039 20.8 5.96706 21.3667 6.65039 21.3667H16.4837V18.8667H6.65039Z" fill={color}/>
    </svg>
  );
};

OutIc.propTypes = {
  color: PropTypes.string,
}
OutIc.defaultProps = {
  color: '#FF1148',
}

export default OutIc;

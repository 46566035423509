import React from 'react';
import PropTypes from 'prop-types';

const TickIc = ({ color }) => {
  return (
    <svg width="82" height="82" viewBox="0 0 131 134" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.1">
      <path d="M79.9093 13.0832H25.0901C1.27842 13.0832 -12.917 27.2786 -12.917 51.0903V105.844C-12.917 129.721 1.27842 143.917 25.0901 143.917H79.8438C103.655 143.917 117.851 129.721 117.851 105.909V51.0903C117.916 27.2786 103.721 13.0832 79.9093 13.0832ZM83.7688 63.454L46.6776 100.545C45.7617 101.461 44.5188 101.984 43.2105 101.984C41.9022 101.984 40.6593 101.461 39.7434 100.545L21.2305 82.0324C19.3334 80.1353 19.3334 76.9953 21.2305 75.0982C23.1276 73.2011 26.2676 73.2011 28.1647 75.0982L43.2105 90.144L76.8347 56.5199C78.7318 54.6228 81.8718 54.6228 83.7688 56.5199C85.6659 58.4169 85.6659 61.4915 83.7688 63.454Z" fill={color}/>
      </g>
    </svg>
  );
};

TickIc.propTypes = {
  color: PropTypes.string,
}
TickIc.defaultProps = {
  color: "white",
}

export default TickIc;

import React from 'react';
import PropTypes from 'prop-types';

const ProfileIc = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0055 3.33478C15.6389 3.33478 12.0889 6.88478 12.0889 11.2514C12.0889 15.5348 15.4389 19.0014 19.8055 19.1514C19.9389 19.1348 20.0722 19.1348 20.1722 19.1514C20.2055 19.1514 20.2222 19.1514 20.2555 19.1514C20.2722 19.1514 20.2722 19.1514 20.2889 19.1514C24.5555 19.0014 27.9055 15.5348 27.9222 11.2514C27.9222 6.88478 24.3722 3.33478 20.0055 3.33478Z" fill={color}/>
      <path d="M28.4617 23.5847C23.8117 20.4847 16.2284 20.4847 11.5451 23.5847C9.42838 25.0014 8.26172 26.918 8.26172 28.968C8.26172 31.018 9.42839 32.918 11.5284 34.318C13.8617 35.8847 16.9284 36.668 19.995 36.668C23.0617 36.668 26.1284 35.8847 28.4617 34.318C30.5617 32.9014 31.7284 31.0014 31.7284 28.9347C31.7117 26.8847 30.5617 24.9847 28.4617 23.5847Z" fill={color}/>
    </svg>
  );
};

ProfileIc.propTypes = {
  color: PropTypes.string,
}
ProfileIc.defaultProps = {
  color: '#292D32',
}

export default ProfileIc;

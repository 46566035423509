import React from 'react';
import PropTypes from 'prop-types';

const Timer = ({ color, size }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M28.9831 26.1167L22.2498 20H17.7331L10.9998 26.1167C9.11647 27.8167 8.4998 30.4333 9.41647 32.8C10.3331 35.15 12.5665 36.6667 15.0831 36.6667H24.8998C27.4331 36.6667 29.6498 35.15 30.5665 32.8C31.4831 30.4333 30.8665 27.8167 28.9831 26.1167ZM23.0331 30.2333H16.9665C16.3331 30.2333 15.8331 29.7167 15.8331 29.1C15.8331 28.4833 16.3498 27.9667 16.9665 27.9667H23.0331C23.6665 27.9667 24.1665 28.4833 24.1665 29.1C24.1665 29.7167 23.6498 30.2333 23.0331 30.2333Z'
				strokeWidth='1'
				fill={color}
			/>
			<path
				d='M30.5838 7.19998C29.6671 4.84998 27.4338 3.33331 24.9171 3.33331H15.0838C12.5671 3.33331 10.3338 4.84998 9.4171 7.19998C8.5171 9.56665 9.13376 12.1833 11.0171 13.8833L17.7504 20H22.2671L29.0004 13.8833C30.8671 12.1833 31.4838 9.56665 30.5838 7.19998ZM23.0338 12.05H16.9671C16.3338 12.05 15.8338 11.5333 15.8338 10.9166C15.8338 10.3 16.3504 9.78331 16.9671 9.78331H23.0338C23.6671 9.78331 24.1671 10.3 24.1671 10.9166C24.1671 11.5333 23.6504 12.05 23.0338 12.05Z'
				strokeWidth='1'
				fill={color}
			/>
		</svg>
	);
};

Timer.propTypes = {
	color: PropTypes.string,
	size: PropTypes.string,
};
Timer.defaultProps = {
	color: '#333333',
	size: '26',
};

export default Timer;

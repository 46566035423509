import React from 'react';
import PropTypes from 'prop-types';

const ClockIc = ({ color }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0003 2.66667C8.65366 2.66667 2.66699 8.65333 2.66699 16C2.66699 23.3467 8.65366 29.3333 16.0003 29.3333C23.347 29.3333 29.3337 23.3467 29.3337 16C29.3337 8.65333 23.347 2.66667 16.0003 2.66667ZM21.8003 20.76C21.6137 21.08 21.2803 21.2533 20.9337 21.2533C20.7603 21.2533 20.587 21.2133 20.427 21.1067L16.2937 18.64C15.267 18.0267 14.507 16.68 14.507 15.4933V10.0267C14.507 9.48 14.9603 9.02667 15.507 9.02667C16.0537 9.02667 16.507 9.48 16.507 10.0267V15.4933C16.507 15.9733 16.907 16.68 17.3203 16.92L21.4537 19.3867C21.9337 19.6667 22.0937 20.28 21.8003 20.76Z" fill={color}/>
    </svg>
  );
};

ClockIc.propTypes = {
  color: PropTypes.string,
}
ClockIc.defaultProps = {
  color: "#333333",
}

export default ClockIc;

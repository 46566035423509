import React from "react";
import PropTypes from "prop-types";

const CourseIc = ({ color, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.7324 13.6934C24.6828 15.0174 26.0495 16.8112 26.0495 19.2029V23.4739H31.7442V19.2029C31.7442 16.0993 26.6617 14.2628 22.7324 13.6934Z"
        fill={color}
      />
      <path
        d="M20.3564 12.0846C23.5026 12.0846 26.051 9.53622 26.051 6.38994C26.051 3.24366 23.5026 0.695312 20.3564 0.695312C19.6872 0.695312 19.0608 0.837678 18.4629 1.03699C19.6445 2.50336 20.3564 4.36835 20.3564 6.38994C20.3564 8.41153 19.6445 10.2765 18.4629 11.7429C19.0608 11.9422 19.6872 12.0846 20.3564 12.0846Z"
        fill={color}
      />
      <path
        d="M11.8138 12.0846C14.9601 12.0846 17.5084 9.53622 17.5084 6.38994C17.5084 3.24366 14.9601 0.695312 11.8138 0.695312C8.66749 0.695312 6.11914 3.24366 6.11914 6.38994C6.11914 9.53622 8.66749 12.0846 11.8138 12.0846ZM11.8138 3.54263C13.3798 3.54263 14.6611 4.82392 14.6611 6.38994C14.6611 7.95596 13.3798 9.23725 11.8138 9.23725C10.2477 9.23725 8.96645 7.95596 8.96645 6.38994C8.96645 4.82392 10.2477 3.54263 11.8138 3.54263Z"
        fill={color}
      />
      <path
        d="M11.8131 13.5088C8.01192 13.5088 0.423828 15.4165 0.423828 19.2034V23.4744H23.2023V19.2034C23.2023 15.4165 15.6142 13.5088 11.8131 13.5088ZM20.355 20.6271H3.27114V19.2177C3.55587 18.1926 7.96921 16.3561 11.8131 16.3561C15.657 16.3561 20.0703 18.1926 20.355 19.2034V20.6271Z"
        fill={color}
      />
    </svg>
  );
};

CourseIc.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
CourseIc.defaultProps = {
  color: "#333333",
  height: "24px",
  width: "32px",
};

export default CourseIc;
